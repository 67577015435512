import React, { useContext } from 'react';
import { WithStore, Slider, Slide } from 'pure-react-carousel';
import SVGInline from 'react-svg-inline';
import styled from '@emotion/styled';
import { checkCircle } from '@sizle-icons';
import useUserContext from 'src/context/UserContext';

const OnboardingSlider = ({ dismissModal }) => {
  const { user } = useUserContext();

  return (
    <SliderContainer name="onboarding-slider">
      <ExpandedGlow>
        <Slider
          isIntrinsicHeight={true}
          isPlaying={false}
          infinite={false}
          dragEnabled={false}
          touchEnabled={false}
        >
          <Slide index={0}>
            <div className="slide">
              <h2 className="slide-title">Let's get started!</h2>
              <center>
                <h3>
                  Transform documents into data-driven assets with live engagement insights and
                  content analytics.
                </h3>
              </center>
              <div className="slide-body">
                <RecommendationList>
                  <Recommendation>
                    <SVGInline className="check" svg={checkCircle} />
                    Share documents with custom links
                  </Recommendation>
                  <Recommendation>
                    <SVGInline className="check" svg={checkCircle} />
                    See live analytics and content insights
                  </Recommendation>
                  <Recommendation>
                    <SVGInline className="check" svg={checkCircle} />
                    Get reactions, comments and approvals
                  </Recommendation>
                  <Recommendation>
                    <SVGInline className="check" svg={checkCircle} />
                    Organize and collaborate in workspaces
                  </Recommendation>
                </RecommendationList>
              </div>
              <div className="btn-group center">
                <br />
                <button className="btn enhanced-btn" onClick={dismissModal}>
                  Go to your dashboard
                </button>
              </div>
            </div>
          </Slide>
        </Slider>
      </ExpandedGlow>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  .carousel__slide-focus-ring {
    outline: none;
    border: none;
  }
  .carousel__slider--horizontal {
    outline: none;
    height: 470px;
  }
  .slide {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: none;
  }
  .slide-body {
    padding: 0px 0px 10px 20px;
    font-size: 1.3em;
    font-weight: 400;
    text-align: left;
    flex-grow: inherit;
    color: var(--text-color);
  }
  .slide-title {
    padding-top: 20px;
    text-align: center;
    font-size: 2.4em;
    font-weight: 600;
    line-height: 1.2em;
    color: var(--text-color);
  }
  .enhanced-btn {
    background-color: var(--primary-color);
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ExpandedGlow = styled.div`
  animation: glow 2s infinite;
  border-radius: 12px;
  padding: 50px;
  margin-top: -35px;
  margin-bottom: -20px;
  margin-left: -55px;
  margin-right: -55px;
  background: linear-gradient(135deg, rgba(98, 0, 238, 0.2), rgba(0, 123, 255, 0.2));
  box-shadow: 0px 0px 30px rgba(98, 0, 238, 0.6); /* Enhanced glow */
  @keyframes glow {
    0% {
      box-shadow: 0px 0px 20px rgba(98, 0, 238, 0.5);
    }
    50% {
      box-shadow: 0px 0px 35px rgba(98, 0, 238, 0.8);
    }
    100% {
      box-shadow: 0px 0px 20px rgba(98, 0, 238, 0.5);
    }
  }
`;

const RecommendationList = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
`;

const Recommendation = styled.div`
  margin-top: 15px;
  &:first-child {
    margin-top: 15px;
  }
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-right: 15px;
`;

export default WithStore(OnboardingSlider, (state) => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
}));
