import React, { useContext, useEffect, useState, useRef, FormEvent } from 'react'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import { Pane, CheckoutForm } from 'src/modules/billing'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { subscriptionContext } from 'src/context'

const ELEMENTS_OPTIONS = { fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }] }
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

const SubscriptionForm = ({ onComplete, cancel, plan }: {onComplete: () => void, cancel: () => void, plan: {[key: string]: any}}) => {
  const { t } = useTranslation()
  const { currentPlan, paymentMethod, purchaseSubscription } = useContext(subscriptionContext)
  const [submitting, setSubmitting] = useState(false)

  const submitSubscription = async ({ token, promo }: {token: string, promo: string}) => {
    setSubmitting(true)
    try {
      const message = await purchaseSubscription(token, promo, plan.id)
      toastr.info(t(message))
      setSubmitting(false)
      onComplete && onComplete()
    } catch (e) {
      toastr.error(t((e as any)?.message))
      setSubmitting(false)
    }
  }
  const submitUpgrade = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitting(true)
    try {
      const message = await purchaseSubscription(null, (e.currentTarget.elements.namedItem('promo') as HTMLInputElement)?.value, plan.id)
      toastr.info(t(message))
      setSubmitting(false)
      onComplete && onComplete()
    } catch (e) {
      toastr.error(t((e as any)?.message))
      setSubmitting(false)
    }
  }

  return (
    <div className='subscription-form'>
      <div>
        <label className='label'><h2>Update your plan</h2></label>
        {currentPlan?.planTier !== 'Free' && (
          <>
          </>
        )}
      </div>
      {paymentMethod && (
        <form onSubmit={submitUpgrade}>
          <div className='submit-buttons'>
            <button type='submit' className={`btn wide extra-big primary ${submitting && 'loading-spinner'}`}> {t('Proceed')} </button>
            <button type='reset' onClick={cancel} className='btn secondary'>Cancel</button>
            <br></br>
          </div>
        </form>
      )}
      <br></br>
      {!paymentMethod && (
        <>
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
              onSubmit={submitSubscription}
              includePromoField
              actionText='Update plan'
            />
          </Elements>
          <label className='label big'><h5>Need help? Speak to our friendly support team<a className='link' href='https://sizle.io/support'> here</a></h5></label>
        </>
      )}
    </div>
  )
}

export default SubscriptionForm
